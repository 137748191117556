<template>
  <div>
    <titulo-seccion>
      <template slot="area-rutas">
        <a href="/">Inicio</a> / <span class="active">Error</span>
      </template>
      <template slot="area-titulo">
        <h3 class="text-center">Error 404</h3>
      </template>
    </titulo-seccion>

    <div id="content-page" class="container">
      <div class="row">
        <div class="col-md-12">
          <p class="text-center mt-5">
            Error 404, El contenido que solicita no existe.
          </p>
        </div>
      </div>
      <div id="navegacion" class="row mt-5 mb-5">
        
      </div>
    </div>
  </div>
</template>

<script>
import TituloSeccion from '../secciones/TituloSeccion.vue'
export default {
  components: {
    TituloSeccion,
  },
  metaInfo: {
      title: 'Ministerio Público',
      titleTemplate: '%s | Error',
      htmlAttrs: {
          lang: 'es-ES'
      },
  },
}
</script>

<style>
  
</style>
